<template>
  <div class="container">
    <div class="container_title">请输入以下信息进行排名查询</div>
    <div class="container_box">
      <div class="container_box_item">
        <img src="#" alt="">
        <div class="item_label">所属学校：</div>
        <div class="item">
          <div
            class="inp"
            style="line-height: 0.57333rem;"
            @click="onOpen('school')"
            v-if="schoolValue.length === 0"
          >
            请选择所属学校
          </div>
          <div
            class="inp"
            style="line-height: 0.57333rem;"
            @click="onOpen('school')"
            v-if="schoolValue.length !== 0"
          >
            {{ schoolValue }}
          </div>
        </div>
      </div>
      <div class="container_box_item">
        <img src="" alt="">
        <div class="item_label">所属年级：</div>
        <div class="item">
          <div
            class="inp"
            style="line-height: 0.57333rem;"
            v-if="levelValue.length === 0"
          >
            请选择所属年级
          </div>
          <div
            class="inp"
            style="line-height: 0.57333rem;"
            v-if="levelValue.length !== 0"
          >
            {{ levelValue }}
          </div>
        </div>
      </div>
      <div class="container_box_item">
        <img src="#" alt="">
        <div class="item_label">姓&#12288;&#12288;名：</div>
        <div class="item">
          <input type="text" class="inp" placeholder="请输入您的姓名" v-model="formData.username">
        </div>
      </div>
      <div class="container_box_btn" @click="get_Login">
        <img src="#" alt="">
      </div>
    </div>

    <van-popup v-model="schoolShow" round position="bottom" :safe-area-inset-bottom="true" @click-overlay="onCancel">
      <van-picker
        title="请选择所属学校"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        value-key="name"
        ref="picker"
      />
    </van-popup>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data'
import {
  getLogin,
  getSchoolInfo,
} from '@/api'
export default {
  name: "search",
  data() {
    return {
      areaList,
      value: '',
      status: false,
      show: false,
      columns: [],
      schoolValue: '',
      levelValue: '',
      classValue: '',
      schoolShow: false,
      formData: {
        grade_id: '',
        username: ''
      }
    }
  },
  created() {
    this.get_SchoolInfo()
  },
  methods: {
    get_Login() {
      getLogin(this.formData).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.$toast.fail(res.data.msg)
        } else {
          localStorage.setItem('token', res.data.data.userinfo.token)
          this.$toast.success(res.data.msg)
          setTimeout(() => {
            this.$router.push({name: 'paiHang', query: { id: res.data.data.userinfo.grade.type }})
          }, 500)
        }
      })
    },
    get_SchoolInfo() {
      getSchoolInfo().then(res => {
        console.log(res)
        this.columns= res.data.data
      })
    },
    onOpen(type) {
      if (type === 'diqu') {
        this.show = true
      } else if (type === 'school') {
        this.schoolShow = true
      }
    },
    onCancel() {
      this.schoolShow = false
    },
    onConfirm(e, value) {
      this.schoolValue = e[1]
      this.levelValue = e[2]
      //this.classValue = e[2]
      this.formData.grade_id = this.columns[value[0]].children[value[1]].children[value[2]].id
      this.schoolShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  padding: 0 25px 0 25px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  // background: url("https://qingshaoniandati.s3.cn-south-1.jdcloud-oss.com/infobj.png") no-repeat center 0;
  background-size: cover;
  &_title {
    width: 100%;
    padding: 59px 0 59px 0;
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    color: #fff;
  }
  &_box {
    padding: 48px 24px 101px 25px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 25px;
    background-color: #fff;
    &_item {
      display: flex;
      align-items: center;
      margin-bottom: 38px;
      .item_label {
        font-size: 25px;
        font-weight: bold;
      }
      img {
        width: 52px;
        height: 52px;
      }
      .item {
        display: flex;
        align-items: center;
        .inp {
          width: 400px;
          height: 43px;
          border: 1PX solid #969495;
          border-radius: 20px;
          padding-left: 10px;
          color: #969495;
          font-size: 26px;
        }
        .item_border {
          font-size: 28px;
          border: 1PX solid #969495;
          color: #969495;
          border-radius: 10px;
          padding: 10px 85px 10px 25px;
          margin-right: 24px;
          box-sizing: border-box;
        }
      }
    }
    &_btn {
      width: 100%;
      height: 77px;
      text-align: center;
      margin-top: 441px;
      img {
        width: 408px;
      }
    }
  }
  ::v-deep .van-picker__toolbar {
    height: 44PX;
  }
  ::v-deep .van-picker__cancel {
    font-size: 16PX;
  }
  ::v-deep .van-picker__confirm {
    font-size: 16PX;
  }
  ::v-deep .van-picker-column {
    font-size: 16PX;
  }
  ::v-deep .van-ellipsis {
    overflow: visible;
  }
}
</style>